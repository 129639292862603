import PostMessageStrategy from "./PostmessageStrategy";
import {
  ADD_TO_FAVORITES,
  BLOCK_YOURSELF_COMMAND,
  LOGOUT_COMMAND,
  OPEN_CASHBOX_COMMAND,
  OPEN_LOBBY_COMMAND,
  OPEN_PROFILE_COMMAND,
  OPEN_SUPPORT_COMMAND,
  PLAY_FOR_REAL_COMMAND,
  REMOVE_FROM_FAVORITES,
} from "../constants";

function Command () {
  function openLobby () {
    new PostMessageStrategy().run({ command: OPEN_LOBBY_COMMAND });
  }

  function blockYourself () {
    new PostMessageStrategy().run({ command: BLOCK_YOURSELF_COMMAND });
  }

  function openProfile () {
    new PostMessageStrategy().run({ command: OPEN_PROFILE_COMMAND });
  }

  function openSupport () {
    new PostMessageStrategy().run({ command: OPEN_SUPPORT_COMMAND });
  }

  function openCashier () {
    new PostMessageStrategy().run({ command: OPEN_CASHBOX_COMMAND });
  }

  function playForReal () {
    new PostMessageStrategy().run({ command: PLAY_FOR_REAL_COMMAND });
  }

  function logout () {
    new PostMessageStrategy().run({ command: LOGOUT_COMMAND });
  }

  function addToFavorites () {
    new PostMessageStrategy().run({ command: ADD_TO_FAVORITES });
  }

  function removeFromFavorites () {
    new PostMessageStrategy().run({ command: REMOVE_FROM_FAVORITES });
  }

  return {
    addToFavorites,
    blockYourself,
    logout,
    openCashier,
    openLobby,
    openProfile,
    openSupport,
    playForReal,
    removeFromFavorites,
  };
}

const command = new Command();

export default command;
