import injectScript from "../utils/injectScript";
import consoleLog from "../utils/consoleLog";

let loadOneClickData = null;
let root = null;
let oneClickLaunchData = null;
let widget = null;

function isOneClickEnabled () {
  return window.USafeOneClickWidget;
}

async function injectOneClick ({ loadOneClick }) {
  if (isOneClickEnabled()) {
    return null;
  }

  loadOneClickData = loadOneClick;

  try {
    const { js, css } = await fetchOneClickData();

    injectScript({
      src: css,
      type: "text/css",
      rel: "stylesheet",
      onload: () => {
        injectScript({ src: js });
      },
    });
  } catch (e) {
    consoleLog("1click injecting error")
  }
}

async function fetchOneClickData () {
  const { data } = await loadOneClickData();

  oneClickLaunchData = data;

  return data;
}

async function openOneClick ({ container, onReloadGame, onCloseWidget, onOpenCashier }) {
  if (!isOneClickEnabled()) {
    return
  }

  const { token } = oneClickLaunchData;

  root = container;

  widget = await window.USafeOneClickWidget.open(
    `${token}`,
    root, {
      onCloseWidget,
      onReloadGame,
      onOpenCashier,
    },
  );
}

function closeOneClick () {
  if (isOneClickEnabled() && document.body.contains(root)) {
    try {
      window.USafeOneClickWidget.close(root);
    } catch (e) {
      console.log("closeOneClick1", e);
    }

    try {
      widget.close()
    } catch (e) {
      console.log("closeOneClick2", e);
    }

    widget = null
  }
}

export default ({
  injectOneClick,
  fetchOneClickData,
  openOneClick,
  closeOneClick,
  isOneClickEnabled,
});
